<template>
  <div class="P-users-list position-relative">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }" >
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title d-flex">
          <h3 class="secondary--text">Calendar Events</h3>
        </div>
      </div>
      <div class="G-component-body pa-0 page-background">
        <v-main class="P-full-calendar" id="dayspan" v-cloak>
          <!-- @updated="getEventsList" -->
          <ds-calendar-app
            ref="app"
            :calendar="calendar"
            :read-only="readOnly"
            @eventMoved="eventMoved"
            @calendarEventChanged="getEventsList"
          >
            <template
              slot="eventPopover"
              slot-scope="{ calendarEvent, calendar, close, menu }"
            >
              <ds-calendar-event-create-popover
                v-if="menu"
                :calendar-event="calendarEvent"
                :calendar="calendar"
                :close="close"
                :isEditMode="true"
                modal-title="Edit Event"
                @create-popover-closed="getEventsList"
              ></ds-calendar-event-create-popover>

              <!-- <ds-calendar-event-popover
          v-bind="slotData"
          :read-only="readOnly"
          @finish="getEventsList"
        ></ds-calendar-event-popover> -->
            </template>

            <template
              slot="eventCreatePopover"
              slot-scope="{ placeholder, calendar, close }"
            >
              <ds-calendar-event-create-popover
                :calendar-event="placeholder"
                :calendar="calendar"
                :close="close"
                modal-title="Add Event"
                @create-edit="$refs.app.editPlaceholder"
                @create-popover-closed="getEventsList"
              ></ds-calendar-event-create-popover>
            </template>

            <template
              slot="eventTimeTitle"
              slot-scope="{ calendarEvent, details }"
            >
              <div class="d-flex align-center">
                <b class="ds-ev-title">{{ details.title }}</b>
                <span
                  v-if="
                    calendarEvent?.event?.data?.eventData?.state === 'process'
                  "
                  class="mdi mdi-progress-clock P-event-icon"
                ></span>
                <span
                  v-if="
                    calendarEvent?.event?.data?.eventData?.state === 'completed'
                  "
                  class="mdi mdi-progress-check P-event-icon"
                ></span>
                <span
                  v-if="
                    calendarEvent?.event?.data?.eventData?.state === 'cancel'
                  "
                  class="mdi mdi-progress-close P-event-icon"
                ></span>
              </div>
              <span
                v-if="!isExpiredEvent(calendarEvent)"
                @click.stop="
                  eventCancelClick(calendarEvent?.event?.data?.eventData?.id)
                "
                class="mdi mdi-close P-cancel-icon"
              ></span>
              <div class="ds-ev-description">
                {{ getCalendarTime(calendarEvent) }}
              </div>
            </template>
          </ds-calendar-app>
          <WarningModal
            v-if="showWarningModal"
            :isModalOpen="showWarningModal"
            :isLoading="warningModalIsLoading"
            :modalText="warningModalText"
            :isDangerButton="true"
            :modalWidth="600"
            btnText="Cancel"
            modalHeading="Cancel this event?"
            @closeModal="closeWarningModal"
            @btnFunction="warningModalBtnFunc"
          >
            <template #body>
              <quill-editor
                v-model="eventCancelComment"
                :options="{ placeholder: 'Comment...' }"
                :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
              ></quill-editor>
            </template>
          </WarningModal>
        </v-main>
      </div>
    </div>
    <div
      class="G-filter-component page-background"
      :class="{ 'P-filter-open': isOpenFilter}"
    >

      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @getEventsList="getEventsList" :installersList="installersList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetData"
        >
        </ott-button>
      </div>
    </div>

    <PageLoader v-if="isLoading" />
  </div>
</template>

<script>
import { dsMerge } from "./functions";
import { Calendar, Day, Weekday, Month, Sorts } from "dayspan";
import { mapActions, mapMutations, mapState } from "vuex";
import WarningModal from "@/components/WarningModal";
import FilterData from "./components/filter/FilterData";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButton from "@/components/vuetifyComponents/OttButton";
import PageLoader from "@/components/pageLoader/PageLoader";

export default {
  name: "FullCalendar",

  components: {
    WarningModal,
    FilterData,
    OttDialog,
    OttCheckbox,
    OttButton,
    PageLoader
  },

  mixins: [DateFormatMixin, ConvertTimeZoneMixin],

  data: () => ({
    isLoading: false,
    storeKey: "FullCalendar",
    calendar: Calendar.weeks(),
    readOnly: false,
    defaultEvents: [],
    warningModalText: `<p>You're about to <span class='text-lowercase'><ins>cancel</ins></span> this event. <br>
       Please add a reason to cancel this event.</p>`,
    showWarningModal: false,
    warningModalIsLoading: false,
    selectedEvent: '',
    installersList: [],
    eventCancelComment: '',
  }),


  async created() {
    this.packagesSetData({ subscriptions: [] }); // On opening Calendar page, resetting subscriptions array
    await this.getEventsList();

    //Ggetting users list to show installers list
    await this.getUsersList({ limit: 100000 })
    if (this.usersList.length) {
      const installers = this.usersList.filter(item => item.rolesInfo.equipmentInstaller)
    console.log(this.usersList)
      installers.map((item) => {
        this.installersList.push({
          name: `${item.firstname} ${item.lastname} (${item.provider.name && item.provider.name.length ? item.provider.name[0].name : 'Unknown Company'})`,
          value: item.id
        })
      })
    }
  },

  mounted() {
    window.app = this.$refs.app;
    this.$hubEventBus.$on("appointment-update", this.handleAppointmentUpdate); // if loggedIn get permissions with WS
  },

  beforeDestroy() {
    this.$hubEventBus.$off("appointment-update", this.handleAppointmentUpdate); // if loggedIn get permissions with WS
    this.setData({ filterData: null })
    this.filterModel.reset()
  },

  computed: {
    ...mapState({
      calendarEvents: (state) => state.fullCalendar.calendarEvents,
      isOpenFilter: (state) => state.fullCalendar.isOpenFilter,
      usersList: (state) => state.users.dataTable,
      filterData: (state) => state.fullCalendar.filterData,
      filterModel: (state) => state.fullCalendar.filterModel
    }),
  },

  methods: {
    ...mapActions({
      getEvents: "fullCalendar/getEvents",
      deleteEvent: "fullCalendar/deleteEvent",
      editEvent: "fullCalendar/editEvent",
      getUsersList: 'users/getCalendarUsersList',
      addEventComment: "fullCalendar/addEventComment",
    }),
    ...mapMutations({
      setData: "fullCalendar/setData",
      packagesSetData: "packagesModule/setData",
    }),

    
    async handleAppointmentUpdate(event) {
      await this.getEventsList();
      await this.getUsersList({ limit: 100000 })
      if (this.usersList.length) {
        const installers = this.usersList.filter(item => item.rolesInfo.equipmentInstaller)
      console.log(this.usersList)
        installers.map((item) => {
          this.installersList.push({
            name: `${item.firstname} ${item.lastname} (${item.provider.name && item.provider.name.length ? item.provider.name[0].name : 'Unknown Company'})`,
            value: item.id
          })
        })
      }
    },

    closeWarningModal() {
      this.selectedEvent = ''
      this.showWarningModal = false; 
      this.eventCancelComment = '';
      this.warningModalIsLoading = false
    },

    resetData() {
      this.filterModel.reset()
    },

    setEventsSideBySide() {
      // Select all elements with class ds-calendar-event-hour
        const elements = document.querySelectorAll('.ds-calendar-event-hour');

        // Create an object to group elements by the combination of top and data-id
        const groups = {};

        elements.forEach((element) => {
          const parent = element.closest('.ds-day');
          if (parent) {
            const dataId = parent.getAttribute('data-id');
            const top = element.style.top;
            const groupKey = dataId + '-' + top;
            if (!groups[groupKey]) {
              groups[groupKey] = [];
            }
            groups[groupKey].push(element);
          }
        });

        // Iterate through groups and set the new width value
        for (const groupKey in groups) {
          const group = groups[groupKey];
          let left = 0;
          if (group.length > 1) {
              const newWidth = 100 / group.length;
              group.forEach((element) => {
                element.style.left = left + '%';
                element.style.width = newWidth + '%';
                left += newWidth;
              });
          } else {
            group.forEach((element) => {
              element.style.left = '0';
              element.style.width = '100%';
            });
          }
        }
    },

    setSameWidthMonthEvents() {
      const eventElements = document.querySelectorAll('.ds-calendar-event-menu');

    // Get the number of children of the parent element
      eventElements.forEach(eventElement => {
        let parentChildren = eventElement.parentElement.children.length
        if (parentChildren > 1) {
          eventElement.style.width = 100 / + parentChildren + '%';
        } else {
          eventElement.style.width = '100%'
        }
      });
    },

    closeFilter() {
      this.setData({ isOpenFilter: false })
    },

    async warningModalBtnFunc() {
      if (this.selectedEvent && this.eventCancelComment.trim()) {
        this.warningModalIsLoading = true;
        await this.cancelEvent() 

        await this.addEventComment({
          eventId: this.selectedEvent,
          comment: this.eventCancelComment,
          isCancel: true
        })

        await this.getEventsList();
      } 
      this.warningModalIsLoading = false;
      this.showWarningModal = false;
      this.eventCancelComment = '';
    },

    eventCancelClick(event) {
      this.selectedEvent = event;
      this.showWarningModal = true;
    },

    async eventMoved({ target, calendarEvent }) {
      const endDate = this.$moment(calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY hh:mm A');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);

      if (calendarEvent.event.data.eventData.state !== 'cancel' && !endDate.isBefore(currentTime, 'day')) {
        this.isLoading = true
        this.setData({
          eventId: calendarEvent.event.data.eventData.id,
        });
  
        const event = {
          startDate: this.$moment(target.start.date).format("MM/DD/YYYY HH:mm"),
          endDate: this.$moment(target.end.date).format("MM/DD/YYYY HH:mm"),
        };
  
        await this.editEvent(event)
        await this.getEventsList()
          .finally(() => this.isLoading = false);
      }
    },

    isExpiredEvent(calendarEvent) {
      // checking if event's End Date and current Date not have days difference then open edit modal
      const endDate = calendarEvent?.end?.date;
      const currentTime = this.$moment(this.newDateWithMainUserTZ);
      // Compare the day of endDate with the current day
      if (
        !endDate.isBefore(currentTime, 'day') &&
        calendarEvent?.event?.data?.eventData?.state !== 'cancel'
      ) {
        return false;
      }

      return true;
    },

    async cancelEvent(event) {
      await this.editEvent({
        eventId: this.selectedEvent,
        state: 'cancel'
      })
    },

    async getEventsList() {
      if (this.calendar?.span?.start && this.calendar?.span?.end) {
        await this.getEvents(this.filterData);
        await this.loadState();
      }
    },

    getCalendarTime(calendarEvent) {
      return (
        calendarEvent.start.format("hh:mm a") +
        " - " +
        calendarEvent.end.format("hh:mm a")
      );
    },

    async loadState() {
      let state = {
        events: [],
      };

      this.defaultEvents = [];

      // Define an object to store unique keys
      // const uniqueKeys = {};

      this.calendarEvents.map(async (event) => {
        // await this.deleteEvent(event.id)
        let startDate = this.$moment(
          event.startDate,
          `DD-MM-YYYY hh:mm a`
        );
        let endDate = this.$moment(
          event.endDate,
          `DD-MM-YYYY hh:mm a`
        );

        // Extract year, month, and dayOfMonth from startDate
        let currentCalendarYear = new Date(
          this.calendar?.span?.start
        ).getFullYear();
        let year = startDate.year();
        let month = startDate.month();
        let dayOfMonth = startDate.date();

        // Calculate the duration in minutes between startDate and endDate
        let durationInMinutes = endDate.diff(startDate, "minutes");

        // Extract hours from startDate without minutes
        let startHours = startDate.hours();

        // Generate a unique key for this event
        // const uniqueKey = `${event?.client}-${year}-${month}-${dayOfMonth}-${durationInMinutes}-${startHours}`;

        const getColor = () => {
          if (event.state === "active") return "white";
          if (event.state === "process") return "#fff4d9";
          if (event.state === "completed") return "#e7f8ed";
          if (event.state === "cancel") return "#fee1e0";
          return "white";
        };

        // Check if the unique key already exists in the uniqueKeys object && years are equal
        // if (
        //   !uniqueKeys[uniqueKey] &&
        //   event?.client &&
        //   currentCalendarYear === year
        // ) {
          const capitalizeWord = (word) =>
            word.charAt(0).toUpperCase() + word.slice(1);

          this.defaultEvents.push({
            data: {
              title: `${capitalizeWord(
                event.title.replace(/([a-z])([A-Z])/g, "$1 $2")
              )}`,
              color: getColor(),
              eventData: { ...event },
            },
            schedule: {
              year: [year],
              month: [month],
              dayOfMonth: [dayOfMonth],
              times: [startHours],
              duration: durationInMinutes,
              durationUnit: "minutes",
            },
          });

          // Mark the unique key as encountered
          // uniqueKeys[uniqueKey] = true;
        // }
      });

      let defaults = this.$dayspan.getDefaultEventDetails();
      state.events = this.defaultEvents;
      state.events.forEach((ev) => {
        ev.data = dsMerge(ev.data, defaults);
      });

      this.$refs.app.setState(state);

      setTimeout(() => {
        this.setEventsSideBySide()
        this.setSameWidthMonthEvents()
      }, 0)
    },
  },
};
</script>

<style lang="scss">
.P-full-calendar {
  width: 100%;
  height: 100%;

  header {
    position: unset !important;
  }
}

main {
  padding-top: 0 !important;
}

.v-btn--flat,
.v-text-field--solo .v-input__slot {
  background-color: #f5f5f5 !important;
  margin-bottom: 8px !important;
}

.P-event-icon {
  font-size: 17px;
  height: 19px;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
}

.P-cancel-icon {
  font-size: 20px;
  height: 19px;
  position: absolute;
  top: 5px;
  right: 3px;
  display: inline-flex;
  align-items: center;
  color: black;

  &:hover {
    color: red;
  }
}

.G-filter-component{
  position: fixed;
  top: 177px;
}

.ds-ev-description {
  margin-top: -3px;
}
</style>
