<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>

    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.date"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData('date')"
        is-range
        locale="en"
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="ott-date-picker-cont Ott-input-class:">
            <div class="G-calendar-input">
              <v-text-field
                v-model="inputValue.start"
                v-on="inputEvents.start"
                outlined
                dense
                height="38"
                label="Start Date"
              />
              <span
                class="P-date-icon neutral--text"
                @click="resetDate"
                :class="
                  !inputValue.start
                    ? 'mdi mdi-calendar-blank-outline P-calendar'
                    : 'icon-cancel'
                "
              ></span>
            </div>
            <span class="pick-to neutral--text">To</span>
            <div class="G-calendar-input">
              <v-text-field
                v-model="inputValue.end"
                v-on="inputEvents.end"
                outlined
                dense
                height="38px"
                label="End Date"
              />
              <span
                @click="resetDate"
                :class="
                  !inputValue.start
                    ? 'mdi mdi-calendar-blank-outline P-calendar'
                    : 'icon-cancel'
                "
                class="P-date-icon neutral--text"
              ></span>
            </div>
          </div>
        </template>
      </DatePicker>
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.eventType"
        @emitFunc="filterData('eventType')"
        :clear="true"
        :items="eventTypes"
        label="Type"
        selected-text="name"
      />
    </div>


    <div class="P-input-block">
      <ott-select
        v-model="filterModel.paymentType"
        @emitFunc="filterData('paymentType')"
        :clear="true"
        :items="paymentTypes"
        label="Payment"
        selected-text="name"
      />
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.state"
        @emitFunc="filterData('state')"
        :clear="true"
        :items="statusList"
        :multiple="false"
        :persistentHint="true"
        label="Status"
        selected-text="name"
      />
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.equipmentInstaller"
        @emitFunc="filterData('equipmentInstaller')"
        :clear="true"
        :items="installersList"
        :multiple="false"
        :persistentHint="true"
        label="Equipment Installer"
        selected-text="name"
      />
    </div>
    <selected-filters-list 
      v-if="filterModel.filterCount.length" 
      @getEventsList="$emit('getEventsList')"
      :installersList="installersList"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import SelectedFiltersList from "./SelectedFiltersList";
import DatePicker from "v-calendar/src/components/DatePicker";

export default {
  name: "FilterData",
  mixins: [DateFormatMixin, ConvertTimeZoneMixin],
  components: {
    OttSelect,
    OttCheckbox,
    OttInput,
    OttRangeSelect,
    SelectedFiltersList,
    DatePicker,
  },

  props: {
    installersList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      eventTypes: [
        {
          name: "New Connection",
          value: 'newConnection',
        },
        {
          name: "Technical",
          value: "technical",
        },
      ],
      paymentTypes: [
        {
          name: "Paid",
          value: "paid",
        },
        {
          name: "Unpaid",
          value: "unpaid",
        },
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Free",
          value: "free",
        },
      ],

      statusList: [
        {
          name: "Active",
          value: "active",
        },
        {
          name: "Process",
          value: "process",
        },
        {
          name: "Completed",
          value: "completed",
        },
        {
          name: "Canceled",
          value: "cancel",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      filterModel: (state) => state.fullCalendar.filterModel,
    }),
  },
  methods: {
    ...mapMutations({
      filterCalendarEvents: "fullCalendar/filterCalendarEvents",
    }),

    resetDate() {
      this.filterModel.date = { 
        start: null, 
        end: null 
      }
    },

    /**
     * Get price group list
     * **/

    filterData(type) {
      this.filterCalendarEvents();
      switch (type) {
        case 'search': {
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'date': {
          if (!this.filterModel.date?.start && !this.filterModel.date?.end) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'eventType': {
          if (!this.filterModel.eventType) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'paymentType': {
          if (!this.filterModel.paymentType) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'state': {
          if (!this.filterModel.state) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'equipmentInstaller': {
          if (!this.filterModel.equipmentInstaller) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
      }

      this.filterModel.getFilterCount();
      this.$emit('getEventsList')
    },
    resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterCalendarEvents();
      this.$emit('getEventsList')
    },
  },
};
</script>
<style lang="scss" scoped>
.P-input-block {
  margin-bottom: 20px;
}
</style>
