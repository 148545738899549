<template>
  <selected-filters
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapMutations, mapState } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";

export default {
  components: { SelectedFilters },
  props: {
    installersList: {
      type: Array,
      default: () => []
    }
  },

  mixins: [ DateFormatMixin ],
  computed: {
    ...mapState({
      filterModel: (state) => state.fullCalendar.filterModel,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: "search",
        },
        {
          ifCondition: this.filterModel.date?.start && this.filterModel.date?.end,
          boxText: `<b>Date</b>: ${this.getDateValue}`,
          resetArgument: "date",
        },
        {
          ifCondition: this.filterModel.eventType !== null,
          boxText: `<b>Type</b>: <span class='text-capitalize'>${ typeof this.filterModel.eventType === 'string' ? this.filterModel.eventType.replace(/([a-z])([A-Z])/g, '$1 $2') : ''}</span>`,
          resetArgument: "eventType",
        },
        {
          ifCondition: this.filterModel.paymentType,
          boxText: `<b>Payment</b>: <span class='text-capitalize'>${this.filterModel.paymentType}</span>`,
          resetArgument: "paymentType",
        },
        {
          ifCondition: this.filterModel.state,
          boxText: `<b>Status</b>: <span class='text-capitalize'>${this.filterModel.state}</span>`,
          resetArgument: "state",
        },
        {
          ifCondition: this.filterModel.equipmentInstaller,
          boxText: `<b>Installer</b>: <span class='text-capitalize'>${ this.installerName }</span>`,
          resetArgument: "equipmentInstaller",
        }, 
        
      ];
    },

    installerName() {
      if (this.installersList.length && this.filterModel.equipmentInstaller) {
        return this.installersList.filter(istaller => istaller.value === this.filterModel.equipmentInstaller)[0].name
      }
      return '-'
    },

    getDateValue(){
      let value = ''
      if (this.filterModel.date?.start && this.filterModel.date?.end){
        value = this.$moment(this.filterModel.date.start).format(this.ottDateFormat) + ' - ' + this.$moment(this.filterModel.date.end).format(this.ottDateFormat)
      }
      return value
    },
  },

  methods: {
    ...mapMutations({
      filterCalendarEvents: "fullCalendar/filterCalendarEvents",
    }),

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterCalendarEvents();
      this.$emit('getEventsList');
    },
  },
};
</script>
