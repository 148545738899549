var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"P-users-list position-relative"},[_c('div',{staticClass:"G-page-component",class:{ 'P-filter-open': _vm.isOpenFilter }},[_vm._m(0),_c('div',{staticClass:"G-component-body pa-0 page-background"},[_c('v-main',{staticClass:"P-full-calendar",attrs:{"id":"dayspan"}},[_c('ds-calendar-app',{ref:"app",attrs:{"calendar":_vm.calendar,"read-only":_vm.readOnly},on:{"eventMoved":_vm.eventMoved,"calendarEventChanged":_vm.getEventsList},scopedSlots:_vm._u([{key:"eventPopover",fn:function({ calendarEvent, calendar, close, menu }){return [(menu)?_c('ds-calendar-event-create-popover',{attrs:{"calendar-event":calendarEvent,"calendar":calendar,"close":close,"isEditMode":true,"modal-title":"Edit Event"},on:{"create-popover-closed":_vm.getEventsList}}):_vm._e()]}},{key:"eventCreatePopover",fn:function({ placeholder, calendar, close }){return [_c('ds-calendar-event-create-popover',{attrs:{"calendar-event":placeholder,"calendar":calendar,"close":close,"modal-title":"Add Event"},on:{"create-edit":_vm.$refs.app.editPlaceholder,"create-popover-closed":_vm.getEventsList}})]}},{key:"eventTimeTitle",fn:function({ calendarEvent, details }){return [_c('div',{staticClass:"d-flex align-center"},[_c('b',{staticClass:"ds-ev-title"},[_vm._v(_vm._s(details.title))]),(
                  calendarEvent?.event?.data?.eventData?.state === 'process'
                )?_c('span',{staticClass:"mdi mdi-progress-clock P-event-icon"}):_vm._e(),(
                  calendarEvent?.event?.data?.eventData?.state === 'completed'
                )?_c('span',{staticClass:"mdi mdi-progress-check P-event-icon"}):_vm._e(),(
                  calendarEvent?.event?.data?.eventData?.state === 'cancel'
                )?_c('span',{staticClass:"mdi mdi-progress-close P-event-icon"}):_vm._e()]),(!_vm.isExpiredEvent(calendarEvent))?_c('span',{staticClass:"mdi mdi-close P-cancel-icon",on:{"click":function($event){$event.stopPropagation();return _vm.eventCancelClick(calendarEvent?.event?.data?.eventData?.id)}}}):_vm._e(),_c('div',{staticClass:"ds-ev-description"},[_vm._v(" "+_vm._s(_vm.getCalendarTime(calendarEvent))+" ")])]}}])}),(_vm.showWarningModal)?_c('WarningModal',{attrs:{"isModalOpen":_vm.showWarningModal,"isLoading":_vm.warningModalIsLoading,"modalText":_vm.warningModalText,"isDangerButton":true,"modalWidth":600,"btnText":"Cancel","modalHeading":"Cancel this event?"},on:{"closeModal":_vm.closeWarningModal,"btnFunction":_vm.warningModalBtnFunc},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('quill-editor',{class:_vm.$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor',attrs:{"options":{ placeholder: 'Comment...' }},model:{value:(_vm.eventCancelComment),callback:function ($$v) {_vm.eventCancelComment=$$v},expression:"eventCancelComment"}})]},proxy:true}],null,false,401466496)}):_vm._e()],1)],1)]),_c('div',{staticClass:"G-filter-component page-background",class:{ 'P-filter-open': _vm.isOpenFilter}},[_c('div',{staticClass:"G-filter-header G-flex G-align-center G-justify-between"},[_c('p',{staticClass:"secondary--text"},[_vm._v("Filter by")]),_c('span',{staticClass:"mdi mdi-close neutral--text",on:{"click":_vm.closeFilter}})]),_c('div',{staticClass:"G-filter-body"},[_c('FilterData',{attrs:{"installersList":_vm.installersList},on:{"getEventsList":_vm.getEventsList}})],1),_c('div',{staticClass:"G-filter-footer"},[_c('ott-button',{staticClass:"primary-lg-btn",attrs:{"text":"Reset Filters","click":_vm.resetData}})],1)]),(_vm.isLoading)?_c('PageLoader'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"G-page-title-block G-flex G-justify-between G-align-center"},[_c('div',{staticClass:"G-component-title d-flex"},[_c('h3',{staticClass:"secondary--text"},[_vm._v("Calendar Events")])])])
}]

export { render, staticRenderFns }